<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  height="24"
  width="24"
  {...$$props}
>
  <desc>
    Interface Essential Alert Triangle 1 Streamline Icon:
    https://streamlinehq.com
  </desc>
  <title>interface-essential-alert-triangle-1</title>
  <g>
    <path
      d="M22.86 20.572499999999998H24v2.2874999999999996h-1.1400000000000001Z"
      fill="currentColor"
      stroke-width="1"
    ></path>
    <path
      d="M21.7125 18.285h1.1475v2.2874999999999996h-1.1475Z"
      fill="currentColor"
      stroke-width="1"
    ></path>
    <path
      d="M1.1400000000000001 22.86h21.72V24H1.1400000000000001Z"
      fill="currentColor"
      stroke-width="1"
    ></path>
    <path
      d="M20.572499999999998 15.997499999999999h1.1400000000000001v2.2874999999999996h-1.1400000000000001Z"
      fill="currentColor"
      stroke-width="1"
    ></path>
    <path
      d="M19.424999999999997 13.7175h1.1475v2.2800000000000002H19.424999999999997Z"
      fill="currentColor"
      stroke-width="1"
    ></path>
    <path
      d="M18.285 11.43h1.1400000000000001v2.2874999999999996h-1.1400000000000001Z"
      fill="currentColor"
      stroke-width="1"
    ></path>
    <path
      d="M17.145 9.1425h1.1400000000000001v2.2874999999999996h-1.1400000000000001Z"
      fill="currentColor"
      stroke-width="1"
    ></path>
    <path
      d="M15.997499999999999 6.855h1.1475v2.2874999999999996h-1.1475Z"
      fill="currentColor"
      stroke-width="1"
    ></path>
    <path
      d="M14.857499999999998 4.5675h1.1400000000000001v2.2874999999999996h-1.1400000000000001Z"
      fill="currentColor"
      stroke-width="1"
    ></path>
    <path
      d="M13.7175 2.2874999999999996h1.1400000000000001v2.2800000000000002h-1.1400000000000001Z"
      fill="currentColor"
      stroke-width="1"
    ></path>
    <path
      d="m13.7175 15.997499999999999 -3.435 0 0 1.1475 -1.1400000000000001 0 0 3.4275 1.1400000000000001 0 0 1.1400000000000001 3.435 0 0 -1.1400000000000001 1.1400000000000001 0 0 -3.4275 -1.1400000000000001 0 0 -1.1475z"
      fill="currentColor"
      stroke-width="1"
    ></path>
    <path
      d="M13.7175 6.855h-3.435v1.1475h-1.1400000000000001v4.5675h1.1400000000000001v2.2874999999999996h3.435v-2.2874999999999996h1.1400000000000001v-4.5675h-1.1400000000000001Zm0 4.574999999999999h-1.1475v-2.2874999999999996h-1.1400000000000001v-1.1400000000000001h1.1400000000000001v1.1400000000000001h1.1475Z"
      fill="currentColor"
      stroke-width="1"
    ></path>
    <path
      d="M12.57 1.1400000000000001h1.1475v1.1475h-1.1475Z"
      fill="currentColor"
      stroke-width="1"
    ></path>
    <path
      d="M11.43 0h1.1400000000000001v1.1400000000000001h-1.1400000000000001Z"
      fill="currentColor"
      stroke-width="1"
    ></path>
    <path
      d="M10.2825 1.1400000000000001h1.1475v1.1475h-1.1475Z"
      fill="currentColor"
      stroke-width="1"
    ></path>
    <path
      d="M9.1425 2.2874999999999996h1.1400000000000001v2.2800000000000002h-1.1400000000000001Z"
      fill="currentColor"
      stroke-width="1"
    ></path>
    <path
      d="M8.0025 4.5675h1.1400000000000001v2.2874999999999996h-1.1400000000000001Z"
      fill="currentColor"
      stroke-width="1"
    ></path>
    <path
      d="M6.855 6.855h1.1475v2.2874999999999996H6.855Z"
      fill="currentColor"
      stroke-width="1"
    ></path>
    <path
      d="M5.715 9.1425h1.1400000000000001v2.2874999999999996H5.715Z"
      fill="currentColor"
      stroke-width="1"
    ></path>
    <path
      d="M4.5675 11.43h1.1475v2.2874999999999996H4.5675Z"
      fill="currentColor"
      stroke-width="1"
    ></path>
    <path
      d="M3.4275 13.7175h1.1400000000000001v2.2800000000000002H3.4275Z"
      fill="currentColor"
      stroke-width="1"
    ></path>
    <path
      d="M2.2874999999999996 15.997499999999999h1.1400000000000001v2.2874999999999996H2.2874999999999996Z"
      fill="currentColor"
      stroke-width="1"
    ></path>
    <path
      d="M1.1400000000000001 18.285h1.1475v2.2874999999999996H1.1400000000000001Z"
      fill="currentColor"
      stroke-width="1"
    ></path>
    <path
      d="M0 20.572499999999998h1.1400000000000001v2.2874999999999996H0Z"
      fill="currentColor"
      stroke-width="1"
    ></path>
  </g>
</svg>

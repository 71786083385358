<script lang="ts">
  import { Toast } from "flowbite-svelte";
  import { onDestroy } from "svelte";
  import { slide } from "svelte/transition";
  import WarningIcon from "../icons/WarningIcon.svelte";
  import { ErrorStore } from "./ErrorReporter";
  $: error = ErrorStore;
  onDestroy(() => {
    error.destroy();
  });
</script>

<Toast
  transition={slide}
  params={{ duration: 250, delay: 0 }}
  open={$error !== null}
  dismissable={true}
  color="orange"
  on:close={() => ($error = null)}
  position="none"
  divClass="rounded-lg flex items-center w-full shadow text-gray-400 bg-gray-800 gap-3 z-50 w-auto p-3 z-50 w-auto border-2 border-red-700 dark:border-red-500"
>
  <WarningIcon class="text-yellow-500 text-5xl font-semibold p-1" slot="icon" />
  <div class="text-red-700">
    {$error?.title ?? "An error occurred."}
  </div>

  <div class="font-semibold text-grey-400">
    {$error?.message ?? "Unknown error."}
  </div>
</Toast>
